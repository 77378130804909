import { Select } from 'antd';
import { ErrorMessage, Field, FieldInputProps, FormikProps } from 'formik';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
const { Option } = Select;

const SelectOption: FC<{
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  props: any;
  options?: [
    {
      label: any;
      value: any;
    },
  ];
  required?: boolean;
  hasAllOption?: boolean;
  icon?: any;
}> = ({ options, hasAllOption, ...props }) => {
  const { t } = useTranslation();
  return (
    <Select {...props}>
      {hasAllOption && (
        <Option value="" key="">
          {t('career.txtAllCategories')}
        </Option>
      )}
      {options.map((item) => (
        <Option value={item.value} key={item.value}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
};

export const FieldSelectOption: FC<{
  name: string;
  required?: boolean;
  options?: any;
  value?: any;
  label?: string;
  hasAllOption?: boolean;
  suffixIcon?: any;
  onChange?: any;
}> = ({ name, hasAllOption, required = false, options, ...propsField }) => {
  return (
    <>
      <Field
        name={name}
        component={SelectOption}
        required={required}
        options={options}
        hasAllOption={hasAllOption}
        {...propsField}
      />
      <ErrorMessage name={name} component="div" className="text-error" />
    </>
  );
};

import { Carousel } from 'antd';
import { useTranslation } from 'next-i18next';
import React from 'react';

const Ekoioser = () => {
  const { t } = useTranslation();
  const data = [
    {
      name: t('about-us.namTruong'),
      position: t('about-us.postionNamTruong'),
      quote: t('career.quoteNamTruong'),
      imgQuote: 'images/quote-orchid.png',
      background: 'images/bg-namtruong.png',
    },
    {
      name: t('about-us.nameThuongTran'),
      position: t('about-us.positionThuongTran'),
      quote: t('career.quoteThuongTran'),
      imgQuote: 'images/quote-gray.png',
      background: 'images/bg-thuongtran.png',
    },
    {
      name: t('about-us.namNguyenDuy'),
      position: t('career.txtDivisionManager'),
      quote: t('career.quoteDuyNguyen'),
      imgQuote: 'images/quote-yellow.png',
      background: 'images/bg-duynguyen.png',
    },
    {
      name: t('about-us.toanPham'),
      position: t('about-us.positionToanPham'),
      quote: t('career.quoteKhanhToan'),
      imgQuote: 'images/quote-sky.png',
      background: 'images/bg-khanhtoan.png',
    },
    {
      name: t('about-us.tamLy'),
      position: t('about-us.positionTamLy'),
      quote: t('career.quoteTamLy'),
      imgQuote: 'images/quote-pink.png',
      background: 'images/bg-tamly.png',
    },
    {
      name: t('about-us.tuyetBui'),
      position: t('about-us.positionTuyetBui'),
      quote: t('career.quoteTuyetBui'),
      imgQuote: 'images/quote-eggplant.png',
      background: 'images/bg-tuyetbui.png',
    },
  ];
  const settings = {
    swipeToSlide: true,
    infinite: true,
    autoplay: true,
    centerPadding: '60px',
    draggable: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    autoplaySpeed: 5000,
    speed: 500,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="ekoioser">
      <div className="title">
        <h2>{t('career.titleEkoioer')}</h2>
      </div>
      <div className="slide">
        <Carousel {...settings}>
          {data.map((item, key) => {
            return (
              <div className="item" key={key}>
                <div className="quote">
                  <img src={item.imgQuote} alt="quote" />
                  <span>{item.quote}</span>
                </div>
                <img
                  src={item.background}
                  alt={item.name}
                  className="background"
                />
                <div className="content">
                  <div className="name">
                    <span>{item.name}</span>
                  </div>
                  <div className="position">
                    <span>{item.position}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Ekoioser;

import React, { FC, Fragment } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { Space } from 'antd';
import { careerLevelOptions } from '@common/constant';
import { useTranslation } from 'next-i18next';
import { Job, Priority } from '@models/job';
import { ClockIcon, CategoyIcon, FireIcon, StackIcon } from '@public/icons';

const CardJob: FC<Job> = ({
  title,
  jobType,
  careerLevel,
  categories,
  slug,
  priority,
  direction = 'vertical',
}) => {
  const { t } = useTranslation();
  const renderCareerLevel = (item) => {
    return careerLevelOptions(t).find((option) => option.key === item).label;
  };

  return (
    <div
      className={classNames('card-job', {
        horizontal: direction === 'horizontal',
      })}
    >
      <Link href={`/jobs/${slug}`}>
        <a>
          <div className="block-top">
            <span className="name-job">
              {title?.replace('[Ekoios Technology]', '')}
            </span>
            <span className={`status status-${priority}`}>
              {[Priority.URGENT, Priority.HOT].includes(priority) && (
                <span>
                  <FireIcon />
                </span>
              )}
              <div>{priority}</div>
            </span>
          </div>
          <div className="block-main">
            <div className="main-horizontal">
              <span className="job-type item">
                <ClockIcon />
                <div className="item-label">{jobType}</div>
              </span>
              <span className="category item">
                <CategoyIcon />
                <Space size={4}>
                  {careerLevel?.map((item, index) => (
                    <Fragment key={index}>
                      {index < careerLevel.length - 1
                        ? `${renderCareerLevel(item)},`
                        : `${renderCareerLevel(item)}`}
                    </Fragment>
                  ))}
                </Space>
              </span>
            </div>
            {categories && (
              <div className="main-vertical">
                <StackIcon />
                <div>
                  {categories?.map((item) => (
                    <span key={item?.name} className="category">
                      {item?.name}{' '}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        </a>
      </Link>
    </div>
  );
};

export default CardJob;

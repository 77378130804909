import React from 'react';
import { GetServerSideProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import LatestJob from '@components/pages/home/LatestJob';
import BestSupport from '@components/pages/home/BestSupport';
import ValueSuccess from '@components/pages/home/ValuesSuccess';
import Ekoioser from '@components/pages/home/Ekoioser';
import BuildFuture from '@components/pages/home/BuildFuture';
import Banner from '@components/Banner';
import FormSearchJob from '@components/Form/FormSearchJob';
import HeadSEO from '@components/HeadSEO';
import { jobAPI } from '@apiClient/post-api';
import { Priority } from '@models/job';

const CareerPage = ({ categories, latestJob }) => {
  const { t } = useTranslation();

  return (
    <div className="career">
      <HeadSEO
        title="Ekotek Technology Careers | Join us for a wonderful adventure"
        description="Ekotek careers · Available positions at Ekotek · Build an exciting and dynamic career with unlimited growth potential. Join the team of professionals, innovate and succeed!"
        keywords="ekotek careers"
        image={`${process.env.NEXT_PUBLIC_SITE_URL}/images/preview-careers.png`}
      />
      <Banner
        title={t('career.titleCareer')}
        subtitle={t('career.subtitleCareer')}
        childComponent={<FormSearchJob categories={categories} />}
        background="/images/banner-career.jpg"
      />
      <div className="container">
        <LatestJob latestJob={latestJob} />
        <ValueSuccess />
        <Ekoioser />
        <BestSupport />
        <BuildFuture />
      </div>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  locale,
}: any) => {
  const { HOT, URGENT } = Priority;
  const listCategories = await jobAPI
    .getTerms('job_listing_category')
    .then(function (response) {
      const newData = response.data.map((item) => {
        return {
          name: item.name,
          term_id: item.term_id,
        };
      });
      return newData;
    })
    .catch(function (error) {
      console.log(error);
    });

  const latestJob = await jobAPI
    .getList({
      params: { per_page: 100 },
    })
    .then(function (response) {
      const newData = response?.data?.posts
        .map((item) => {
          return {
            title: item?.post_title?.replace('[Ekoios Technology] ', ''),
            priority: item?.custom_field?.priority,
            careerLevel: item?.custom_field?.career_level,
            jobType: item?.job_type?.name,
            categories: item?.categories,
            slug: item?.post_name,
          };
        })
        .filter((item) => [HOT, URGENT].includes(item?.priority))
        .filter((item, index) => index < 5);

      return newData;
    })
    .catch(function (error) {
      console.log(error);
    });

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      categories: listCategories,
      latestJob: JSON.parse(JSON.stringify(latestJob)),
    },
  };
};

export default CareerPage;

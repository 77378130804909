import { Col, Row } from 'antd';
import { useTranslation } from 'next-i18next';
import React from 'react';

const BestSupport = () => {
  const { t } = useTranslation();
  const data = [
    {
      icon: 'images/img-work.png',
      subtitle: t('career.txtFlexibleWork'),
      title: t('career.subFlexibleWork'),
    },
    {
      icon: 'images/benefits.png',
      subtitle: t('career.txtHealthBenefits'),
      title: t('career.subHealthBenefits'),
    },
    {
      icon: 'images/treats.png',
      subtitle: t('career.txtTreats'),
      title: t('career.subTreats'),
    },
    {
      icon: 'images/compensation.png',
      subtitle: t('career.txtCompensation'),
      title: t('career.subCompensation'),
    },
    {
      icon: 'images/learning.png',
      subtitle: t('career.txtLearning'),
      title: t('career.subLearning'),
    },
    {
      icon: 'images/facilities.png',
      subtitle: t('career.txtFacilities'),
      title: t('career.subFacilities'),
    },
  ];
  return (
    <div className="best-support">
      <h2 className="title">{t('career.titleBestSupport')}</h2>
      <Row gutter={[1, 1]}>
        {data.map((item, idx) => {
          return (
            <Col xs={24} sm={24} md={12} xl={8} key={idx}>
              <div className="block">
                <div className="block-title">
                  <img src={item.icon} alt={item.title} />
                  <div>{item.title}</div>
                </div>
                <div className="block-subtitle">{item.subtitle}</div>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default BestSupport;

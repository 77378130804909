export enum Priority {
  URGENT = 'urgent',
  HOT = 'hot',
  OPEN = 'open',
  CLOSE = 'close',
}

export interface Job {
  title: string;
  jobType?: string;
  categories?: [any];
  careerLevel?: [string];
  slug?: string;
  priority?: Priority.URGENT | Priority.HOT | Priority.OPEN | Priority.CLOSE;
  direction?: 'horizontal' | 'vertical';
}

import { ButtonPrimary } from '@components/Button';
import { Col, Row } from 'antd';
import { useTranslation } from 'next-i18next';
import React from 'react';
import Link from 'next/link';
const BuildFuture = () => {
  const { t } = useTranslation();
  const data = [
    {
      img: 'images/future-employer-one.png',
      alt: 'future-employer',
    },
    {
      img: 'images/talking-partner.png',
      alt: 'talking-partner',
    },
    {
      img: 'images/employer-relax.png',
      alt: 'employer-relax',
    },

    {
      img: 'images/employer-discussion.png',
      alt: 'employer-discussion',
    },
  ];
  return (
    <div className="build-future">
      <Row>
        <Col xs={24} sm={24} md={12}>
          <div className="block">
            {data.map((item, key) => {
              return (
                <div className="box" key={key}>
                  <img src={item.img} alt={item.alt} />
                </div>
              );
            })}
          </div>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <div className="content">
            <div className="title">
              <h2>{t('career.titleFuture')}</h2>
            </div>
            <div className="subtitle">
              <span>{t('career.subtitleFuture')}</span>
            </div>
            <Link href="/jobs">
              <a>
                <ButtonPrimary>{t('career.txtViewPosition')}</ButtonPrimary>
              </a>
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BuildFuture;

import React from 'react';
import { Form, Formik } from 'formik';
import { Col, Row } from 'antd';
import { FieldTextInput } from '@components/FormItem/Input';
import { ButtonPrimary } from '@components/Button';
import { useTranslation } from 'next-i18next';
import { SearchIcon } from '@public/icons';
import { useRouter } from 'next/router';
import { DownArrowSmallIcon } from '@public/icons';
import { FieldSelectOption } from '@components/FormItem/Select';
const FormSearchJob = ({ categories }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const handleSubmit = (data) => {
    router.push(
      {
        pathname: '/jobs',
        query: { searchText: data.key, category: data.category },
      },
      undefined,
      { shallow: false },
    );
  };
  const onChange = (setFieldValue) => (e) => {
    setFieldValue('category', e);
  };
  return (
    <div className="form-search-job">
      <Formik
        initialValues={{
          key: '',
          category: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="form-search-job__form">
            <Row gutter={[21, { md: 15 }]}>
              <Col xs={24} md={10}>
                <FieldTextInput
                  name="key"
                  value={values.key}
                  placeholder={t('career.txtSearchJob')}
                  prefix={<SearchIcon />}
                />
              </Col>
              <Col xs={24} md={7}>
                <FieldSelectOption
                  name="category"
                  options={categories.map((item) => ({
                    value: item.term_id,
                    label: item.name,
                  }))}
                  hasAllOption={true}
                  value={values.category}
                  onChange={onChange(setFieldValue)}
                  suffixIcon={<DownArrowSmallIcon />}
                />
              </Col>
              <Col xs={24} md={4}>
                <ButtonPrimary
                  htmlType="submit"
                  className="button-submit"
                  disabled={!values.key && !values.category}
                >
                  {t('career.txtSearch')}
                </ButtonPrimary>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormSearchJob;

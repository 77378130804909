import { Col, Row } from 'antd';
import React from 'react';
import Link from 'next/link';
import CardJob from '@components/CardJob';
import { ButtonLearnMore } from '@components/Button';
import { useTranslation } from 'next-i18next';

const LatestJob = ({ latestJob }) => {
  const { t } = useTranslation();
  return (
    <div className="latest-job">
      <div className="title">
        <h2>{t('career.txtLatesJob')}</h2>
      </div>
      <Row gutter={[25, 36]}>
        {latestJob.map((item, idx) => {
          return (
            <Col xs={24} sm={24} md={12} xl={8} key={idx}>
              <CardJob {...item} />
            </Col>
          );
        })}
        <Col xs={24} sm={24} md={12} xl={8}>
          <div className="discover">
            <img
              src="images/bg-discovery.png"
              alt="discovery"
              className="background"
            />
            <Link href="/jobs">
              <a>
                <ButtonLearnMore text="Discover all jobs" />
              </a>
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LatestJob;

import React, { FC } from 'react';
import Head from 'next/head';

type HeadProps = {
  title: string;
  description?: string;
  keywords?: string;
  image?: string;
};

const HeadSEO: FC<HeadProps> = ({ title, description, keywords, image }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} key="og_title" />
      <meta name="twitter:title" content={title} key="twitter_title" />
      {keywords && <meta name="keywords" content={keywords} />}

      {description && (
        <>
          <meta
            property="og:description"
            content={description}
            key="og_description"
          />
          <meta
            name="twitter:description"
            content={description}
            key="twitter_description"
          />
        </>
      )}

      {image && (
        <>
          <meta property="og:image" content={image} key="og:image" />
          <meta
            property="og:image:secure_url"
            content={image}
            key="og:image:secure_url"
          />
          <meta name="twitter:image" content={image} key="twitter:image" />
        </>
      )}
    </Head>
  );
};

export default HeadSEO;

import { Col, Row } from 'antd';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { Carousel } from 'antd';
const ValueSuccess = () => {
  const { t } = useTranslation();
  const dataLaptop = [
    {
      text: true,
      title: t('career.txtBold'),
      subtitle: t('career.subBold'),
      background: '#f15622',
      hoverColor: 'bg-blue',
    },
    {
      imageBig: 'images/metting-employer-big-one.png',
    },
    {
      text: true,
      title: t('career.txtProactive'),
      subtitle: t('career.subProactive'),
      background: '#052148',
      hoverColor: 'bg-orange',
    },
    {
      imageBig: 'images/metting-employer-big-two.png',
    },
    {
      text: true,
      title: t('career.txtAglie'),
      subtitle: t('career.subAglie'),
      background: '#f15622',
      hoverColor: 'bg-blue',
    },
    {
      imageSmall: 'images/metting-employer-small-one.png',
    },
    {
      imageSmall: 'images/metting-employer-small-two.png',
    },
    {
      text: true,
      title: t('career.txtEmpower'),
      subtitle: t('career.subEmpower'),
      background: '#052148',
      hoverColor: 'bg-orange',
    },
    {
      imageSmall: 'images/metting-employer-small-three.png',
    },
    {
      text: true,
      title: t('career.txtDriven'),
      subtitle: t('career.subDriven'),
      background: '#f15622',
      hoverColor: 'bg-blue',
    },
  ];
  const dataMobile = [
    {
      title: t('career.txtBold'),
      image: 'images/metting-employer-big-one.png',
      subtitle: t('career.subBold'),
      backgroundColor: '#f15622',
    },
    {
      title: t('career.txtProactive'),
      image: 'images/metting-employer-small-one.png',
      subtitle: t('career.subProactive'),
      backgroundColor: '#052148',
    },
    {
      title: t('career.txtAglie'),
      image: 'images/metting-employer-big-two.png',
      subtitle: t('career.subAglie'),
      backgroundColor: '#f15622',
    },
    {
      title: t('career.txtEmpower'),
      image: 'images/metting-employer-small-two.png',
      subtitle: t('career.subEmpower'),
      backgroundColor: '#052148',
    },
    {
      title: t('career.txtDriven'),
      image: 'images/metting-employer-small-three.png',
      subtitle: t('career.subDriven'),
      backgroundColor: '#f15622',
    },
  ];
  const settings = {
    swipeToSlide: true,
    infinite: true,
    autoplay: true,
    centerPadding: '60px',
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    time: 5000,
    speed: 1000,
    autoplaySpeed: 10000,
  };
  return (
    <div className="value-success">
      <div className="content">
        <div className="title">
          <h2>{t('career.titleSuccessValue')}</h2>
        </div>
        <div className="subtitle">
          <span>{t('career.subSuccessValue')}</span>
        </div>
      </div>
      <div className="block-laptop">
        <Row>
          {dataLaptop.map((item, idx) =>
            item.text ? (
              <Col span={6} key={idx}>
                <div
                  className={`item-text ${item.hoverColor}`}
                  style={{ backgroundColor: item.background }}
                  key={idx}
                >
                  <div className="title">{item.title}</div>
                  <div className="subtitle">{item.subtitle}</div>
                </div>
              </Col>
            ) : item.imageBig ? (
              <Col span={12} key={idx}>
                <div className="item-big">
                  <img src={item.imageBig} alt="meeting employer" />
                </div>
              </Col>
            ) : (
              <Col span={6} key={idx}>
                <div className="item-small">
                  <img src={item.imageSmall} alt="meeting employer" />
                </div>
              </Col>
            ),
          )}
        </Row>
      </div>
      <div className="block-mobile">
        <Carousel {...settings}>
          {dataMobile.map((item, key) => {
            return (
              <Row key={key}>
                <Col xs={24} sm={24}>
                  <div className="item">
                    <img src={item.image} alt="meeting employer" />
                    <div
                      className="content-mobile"
                      style={{
                        backgroundColor: item.backgroundColor,
                      }}
                    >
                      <div className="title">
                        <span>{item.title}</span>
                      </div>
                      <div className="subtitle">
                        <span>{item.subtitle}</span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default ValueSuccess;

const careerLevelOptions = (t) => {
  return [
    {
      label: t('job.textFresher'),
      key: 'fresher',
    },
    {
      label: t('job.textJunior'),
      key: 'junior',
    },
    {
      label: t('job.textMiddle'),
      key: 'middle',
    },
    {
      label: t('job.textSenior'),
      key: 'senior',
    },
    {
      label: t('job.textManager'),
      key: 'manager',
    },
  ];
};

export { careerLevelOptions };

import DividerBig from '@components/DividerBig';
import React, { ReactNode } from 'react';
type propsBanner = {
  title: string;
  subtitle: string;
  childComponent?: ReactNode;
  background: any;
};
const BannerCareer = ({
  title,
  subtitle,
  background,
  childComponent,
}: propsBanner) => {
  return (
    <>
      <div
        className="banner-career"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="block">
          <div className="container">
            <h1 className="title">{title}</h1>
            <div className="subtitle">{subtitle}</div>
            {childComponent}
          </div>
        </div>
        <div className="divide">
          <div className="container">
            <DividerBig />
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerCareer;
